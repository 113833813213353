import React, { Component } from "react"
import { GlobalContainer, Button, GlobalSection } from "../../components/GlobalStyle"
import styled, { css } from "styled-components"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import headerImage from "../../images/slider/slide-2.jpg"

import checked from "../../images/icons/checked.svg"
import dedicated from "../../images/cowork/desk.svg"
import hotdesk from "../../images/cowork/hammock.svg"
import shelf from "../../images/cowork/locker.svg"
import conference from "../../images/cowork/lectern.svg"
import coffee from "../../images/cowork/tea-cup.svg"
import printer from "../../images/cowork/print.svg"
import Map from "../../components/Map"
import SectionContact from "../../components/sectionContact"
import Helmet from "react-helmet"
import { withTranslation } from "react-i18next"

const StyledBody = styled.div`
  background-color: #F4F4F4;
`
const StyledOfferContainer = styled(GlobalContainer)`
  max-width: 1040px;
  margin-bottom: 200px;
  p {
    margin: 20px 0;
    text-align: center;
  }
`
const StyledFAQContainer = styled(GlobalContainer)`
  padding-top: 100px;
  max-width: 1040px;
  margin-bottom: 200px;
  p {
    margin: 20px 0;
  }
  ul {
    li {
      display: flex;
      &:before {
        content: '';
        display: block;
        height: 4px;
        width: 16px;
        background-color: var(--main);
        margin-right: 10px;
        position: relative;
        top: 15px;
      }
    }
    div {
      flex: 1;
      p {
        margin-top: 0;
      }
    }
  }
`
const StyledPageHeaderDetails = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(12,0,46,0.04);
  position: relative;
  transform: translateY(-50%);
  @media(max-width: 800px) {
    flex-direction: column;
    transform: translateY(-50px);
  }
  & > div {
    flex: 1;
    text-align: center;
    padding: 30px 10px;
    font-size: 1.4em;
    line-height: 1.1em;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 20px);
      right: 0;
      display: block;
      height: 40px;
      width: 1px;
      background-color: var(--main);
      @media(max-width: 800px) {
        display: none;
      }
    }
    &:last-of-type:after{
      display: none; 
    }
    span {
      text-transform: uppercase;
      font-weight: bold;
      opacity: .5;
      display: block;
      font-size: .6em;
    }
  }
`
// const StyledPromoBox = styled.div`
//   margin-bottom: 20px;
//   padding: 10px;
//   background-color: rgba(0, 166, 90, 0.1);
//   border: 1px solid rgba(0, 166, 90, 0.3);
// `
const StyledCoworkPlans = styled.div`
  display: flex;
  width: 1300px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin:  20px 0 80px;
  @media(max-width: 1320px) {
    width: 100%;
  }
  @media(max-width: 1100px) {
    flex-direction: column;
  }
`
const StyledCoworkPlansItem = styled.div`
  flex: 1;
  background-color: #fff;
  padding: 20px 20px 40px 20px;
  margin: ${props => props.active ? '0 15px' : '30px 4px'};
  position: relative;
  &:after {
    content: '';
    display: ${props => props.active ? 'block' : 'none'};
    height: calc(100% + 6px);
    width: calc(100% + 6px);
    background-image: linear-gradient(45deg, #2EC796, #A49AFE );
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
  }
  @media(max-width: 1320px) {
    padding: 20px 5px;
  }
})
`
const StyledCoworkPlansHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
  span {
    text-transform: uppercase;
    font-weight: 600;
    font-size: .9em;
    strong {
      display: inline-block;
      margin-left: 4px;
      color: var(--main);
    }
  }
  i {
    font-size: 1.6em;
    font-weight: bold;
    font-style: normal;
  }
  s {
    font-size: 1.8em;
    display: block;
    line-height: 2em;
    position: relative;
    top: -20px;
    opacity: .6;
  }
  & > div {
    margin: 30px 0 10px;
    line-height: 1.6em;
    strong {
      font-size: 4em;
      font-weight: bold;
    }
  }
`
const StyledCoworkPlansDescription = styled.div`
  ul {
  margin-left: 40px;
  @media(max-width: 1100px) {
    width: 200px;
    margin: 0 auto;
  }
    li {
      list-style: none;
      position: relative;
      color: rgba(#000,0.6);
      margin: 6px 0;
      font-size: .9em;
      @media(max-width: 1320px) {
        font-size: 14px;
      }
      &::before {
        content: '';
        display: inline-block;
        height: 16px;
        width: 16px;
        background-image: url(${checked});
        background-size: contain;
        position: absolute;
        left: -30px;
        top: 5px;
        }
      }
    }
`
const StyledHourInfo = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: .9em;
  padding: 0 40px;
  p {
    opacity: .6;
    position: relative;
    top: -30px;
  }
`
const StyledCoworkPerks = styled.div`
  li {
    padding: 20px 0;
  }
  h3 {
    font-size: 1.5em;
    font-weight: 800;
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1.3em;
    }
  }
  p {
    padding-left: 52px;
    opacity: .7;
    margin-top: 10px;
    text-align: left;
    @media (max-width: 500px) {
      text-align: center;
      padding-left: 0;
      font-size: .9em;
    }
  }
`
const StyledCoworkIcon = styled.span`
  display: inline-block;
  height: 42px;
  width: 42px;
  background-size: contain;
  position: relative;
  margin-right: 10px;
  top: 12px;
  background-image: url(${props => props.icon});
  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`
const StyledCoworkingPlanAnnual = styled.span`
  display: block;
  font-size: .8em;
  opacity: .6;
  text-transform: none !important;
  font-weight: 400 !important;
`
const StyledPlansTabs = styled.div`
  display: flex;
  max-width: 500px;
  width: 100%;
  margin: 80px auto 60px;
  border-radius: 100px;
  box-shadow: 
    inset 0 0 5px rgba(0,0,0,0.1),
    inset 0 0 20px rgba(0,0,0,0.05);
`
const StyledPlansTabsTab = styled.div`
  flex: 1;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  ${props => props.active && css `
    background-color: #fff; 
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 30px;
      background-color: var(--main);
      position: absolute;
      bottom: 0;
      left: calc(50% - 10px);
    }
  `}
`
const StyledPlanType = styled.div`
  position: relative;
  .select-options {
    position: absolute;
    background-color: #fff;
    padding: 10px; 
    width: 240px;
    text-align: left;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    border-radius: 4px;
    border: 2px solid #F4F4F4;
    top: 26px;
    display: none;
    margin-top: 10px;
    &:hover {
      display: block;
    }
  }
  span {
    padding: 4px 8px;
    transition: .2s;
    display: block;
    cursor: pointer;
    &:hover {
      background-color: #F4F4F4;
    }
  }
  .selected-option {
    display: inline-block;
    padding: 2px 40px 2px 12px ;
    border-radius: 40px;
    font-size: .9em;
    background-color: #F4F4F4;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    margin-bottom: 40px;
    &.only {
      padding-right: 12px;
      cursor: inherit;
      &:after {
        display: none;
      }
    }
    &:hover {
      border-bottom: 10px solid #fff;
      margin-bottom: 30px;
      & + .select-options {
        display: block;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 10px;
      right: 15px;
      height: 6px;
      width: 6px;
      border-top: 2px solid black;
      border-right: 2px solid black;
      transform: rotate(135deg);
    }
  }
`
const StyledCoworkPlanPrice = styled.div`
  position: relative;
  left: 10px;
  margin-top: 50px !important;
`


const perks = [
  {
    id: 1,
    name: "Biurko Dedykowane",
    description: "Oznacza to, że przez cały okres trwania umowy, możesz korzystać z wybranego przez siebie dedykowanego biurka. Dzięki temu masz pewność, że zawsze będziesz mógł usiąść w tym samym miejscu.",
    icon: dedicated
  },
  {
    id: 2,
    name: "Miejsce Hot Desk",
    description: "Oznacza to, że możesz usiąść na dowolnym miejscu, jeżeli nie jest ono przez nikogo zarezerwowane",
    icon: hotdesk
  },
  {
    id: 3,
    name: "Dedykowana szafka",
    description: "Przez cały czas trwania Twojego pakietu masz dostęp do swojej osobistej szafki, zamykanej na klucz",
    icon: shelf
  },
  {
    id: 4,
    name: "Sala konferencyjna",
    description: "Oznacza to, że w ramach swojego pakietu masz dostęp do sali wideokonferencyjnej",
    icon: conference
  },
  {
    id: 5,
    name: "Darmowa kawa/herbata/woda",
    description: "W ramach naszych pakietów masz dostęp do kuchni, gdzie możesz dowoli częstować się napojami",
    icon: coffee
  },
  {
    id: 6,
    name: "Dostęp do sprzętu biurowego",
    description: "W ramach każdego pakietu posiadasz dostęp do podstawowych urządzeń biurowych, takich jak drukarka czy skaner",
    icon: printer
  }
]

  class CoworkingPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        plan: "płatność miesięczna",
        type: "abonament",
        desk: "biurko dedykowane",
      }
    }

    render() {
      return (
        <StyledBody>
          <Helmet>
            <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "Gdzie znajdę coworking w trójmieście?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Zapraszamy do LesnaHub, słonecznego coworkingu w trójmieście (Sopot). Ta przestrzeń oferuje różnorodne usługi, od jednodniowych karnetów po roczne członkostwo. Odwiedzający mogą wybierać spośród różnych planów członkostwa, w tym stawek miesięcznych i rocznych. Ceny różnią się w zależności od rodzaju i długości członkostwa, które chcesz z nimi wykupić."
                  }
                },{
                  "@type": "Question",
                  "name": "Jakie są zalety korzystania z coworkingu?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Coworking to rosnący trend w miejscu pracy. To przestrzeń, w której mogą spotykać się i pracować ludzie z różnych branż. Zaletą coworkingu jest to, że może pomóc w utrzymaniu koncentracji, stworzeniu środowiska do współpracy i zwiększeniu produktywności."
                  }
                },{
                  "@type": "Question",
                  "name": "Ile kosztuje wynajem biurka w coworkingu?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Koszt wynajęcia biurka w przestrzeni coworkingowej różni się w zależności od lokalizacji i udogodnień. Niektóre przestrzenie coworkingowe pobierają miesięczną opłatę za dostęp do swoich obiektów, podczas gdy inne pobierają opłatę za dzień lub godzinę. Średni koszt wynajmu biurka w przestrzeni coworkingowej w LeśnaHub to około 730 PLN miesięcznie. W ramach tej ceny uzyskasz dedykowane biurko, monitor, darmową kawe/herbate/wode, dostęp do wifi, drukarki i wiele wiele więcej."
                  }
                }]
              }
            `}
            </script>
          </Helmet>
          <SEO
            title="Strefa coworkingowa"
            description="Nasz coworking to idealne miejsce dla osób które nie potrzebują całego biura. Nasz coworking to przestrzeń dla freelancerów i startupów"
          />
          <PageHeader image={headerImage}>
            <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{this.props.t("offerCoworking.header")}</h1>
          </PageHeader>
          <StyledOfferContainer>
            <StyledPageHeaderDetails data-sal="fade" data-sal-delay="500" data-sal-duration="400">
              <div data-sal="slide-up" data-sal-delay="600" data-sal-duration="400">
                <span>{this.props.t("offerCoworking.price")}</span>
                {this.props.t("common.from")} 50zł / {this.props.t("common.day")}
              </div>
              <div data-sal="slide-up" data-sal-delay="650" data-sal-duration="400">
                <span>{this.props.t("common.area")}</span>
                90m2
              </div>
              <div data-sal="slide-up" data-sal-delay="700" data-sal-duration="400">
                <span>{this.props.t("common.location")}</span>
                Sopot
              </div>
              <div data-sal="slide-up" data-sal-delay="750" data-sal-duration="400">
                <span>{this.props.t("offerCoworking.capacity")}</span>
                38 {this.props.t("offerCoworking.desks")}
              </div>
            </StyledPageHeaderDetails>
            <section>
              <p>
							{this.props.t("offerCoworking.descShort")}
              </p>
              {/*<StyledPromoBox>*/}
              {/*  Uwaga! Do 14 lutego cowork dostępny jest w promocyjnych cenach*/}
              {/*</StyledPromoBox>*/}
              <StyledPlansTabs>
                {/*<StyledPlansTabsTab*/}
                {/*  active={this.state.type === "hotseat"}*/}
                {/*  onClick={() => this.setState({type: "hotseat"})} >*/}
                {/*  Hotdesk*/}
                {/*</StyledPlansTabsTab>*/}
                <StyledPlansTabsTab
                  active={this.state.type === "abonament"}
                  onClick={() => this.setState({type: "abonament"})}>
                 	{this.props.t("offerCoworking.packageAbonament")}
                </StyledPlansTabsTab>
              </StyledPlansTabs>

              { this.state.type === "hotseat" &&
              <StyledCoworkPlans>
                <StyledCoworkPlansItem>
                  <StyledCoworkPlansHeader>
                    <span>1 {this.props.t("common.day")}</span>
                    <StyledCoworkPlanPrice>
                      <strong>50</strong>
                      <i>zł</i>
                    </StyledCoworkPlanPrice>
                    <StyledPlanType noOptions>
                      <div className="selected-option only">hotdesk</div>
                    </StyledPlanType>
                  </StyledCoworkPlansHeader>
                  <StyledCoworkPlansDescription>
                    <ul>
                      <li>{this.props.t("offerCoworking.perks8")}</li>
											<li>{this.props.t("offerCoworking.perks2")}</li>
											<li>{this.props.t("offerCoworking.perks3")}</li>
											<li>{this.props.t("offerCoworking.perks4")}</li>
											<li>{this.props.t("offerCoworking.perks5")}</li>
                    </ul>
                  </StyledCoworkPlansDescription>
                </StyledCoworkPlansItem>
                <StyledCoworkPlansItem>
                  <StyledCoworkPlansHeader>
                    <span>3 {this.props.t("offerCoworking.days")}</span>
                    {this.state.desk == "hotdesk" &&
                    <StyledCoworkPlanPrice>
                      <strong>120</strong> <i>zł</i>
                    </StyledCoworkPlanPrice>
                    }
                    {this.state.desk == "biurko dedykowane" &&
                    <StyledCoworkPlanPrice>
                      <strong>145</strong> <i>zł</i>
                    </StyledCoworkPlanPrice>
                    }

                    <StyledPlanType>
                      <div
                        className="selected-option">{this.props.t(this.state.desk)}</div>
                      <div className="select-options">
                        <span onClick={() => this.setState({desk: "hotdesk"})}>Hotdesk</span>
                        <span onClick={() => this.setState({desk: "biurko dedykowane"})}>{this.props.t("offerCoworking.dedicatedDesk")}</span>
                      </div>
                    </StyledPlanType>
                  </StyledCoworkPlansHeader>
                  <StyledCoworkPlansDescription>
                    <ul>
                      {this.state.desk == "hotdesk" &&
                      <li>miejsce hotdesk</li>
                      }
                      {this.state.desk == "biurko dedykowane" &&
                      <li><strong>{this.props.t("offerCoworking.perks1")}</strong></li>
                      }
                    <li>{this.props.t("offerCoworking.perks2")}</li>
											<li>{this.props.t("offerCoworking.perks3")}</li>
											<li>{this.props.t("offerCoworking.perks4")}</li>
											<li>{this.props.t("offerCoworking.perks5")}</li>
                    </ul>
                  </StyledCoworkPlansDescription>
                </StyledCoworkPlansItem>
                <StyledCoworkPlansItem active>
                  <StyledCoworkPlansHeader>
                    <span>
                      <p> 15 {this.props.t("offerCoworking.days")} - <strong>{this.props.t("offerCoworking.mostPopular")}</strong>  </p>
                    </span>
                    {this.state.desk == "hotdesk" &&
                    <StyledCoworkPlanPrice>
                      <strong>340</strong> <i>zł</i>
                    </StyledCoworkPlanPrice>
                    }
                    {this.state.desk == "biurko dedykowane" &&
                    <StyledCoworkPlanPrice>
                      <strong>440</strong> <i>zł</i>
                    </StyledCoworkPlanPrice>
                    }

                    <StyledPlanType>
                      <div
                        className="selected-option">{this.props.t(this.state.desk)}</div>
                      <div className="select-options">
                        <span onClick={() => this.setState({desk: "hotdesk"})}>Hotdesk</span>
                        <span onClick={() => this.setState({desk: "biurko dedykowane"})}>{this.props.t("offerCoworking.dedicatedDesk")}</span>
                      </div>
                    </StyledPlanType>
                  </StyledCoworkPlansHeader>
                  <StyledCoworkPlansDescription>
                    <ul>
                      {this.state.desk == "hotdesk" &&
                      <li>miejsce hotdesk</li>
                      }
                      {this.state.desk == "biurko dedykowane" &&
                      <li><strong>{this.props.t("offerCoworking.perks1")}</strong></li>
                      }
                   	<li></li>
											<li>{this.props.t("offerCoworking.perks2")}</li>
											<li>{this.props.t("offerCoworking.perks3")}</li>
											<li>{this.props.t("offerCoworking.perks4")}</li>
											<li>{this.props.t("offerCoworking.perks5")}</li>
                    </ul>
                  </StyledCoworkPlansDescription>
                </StyledCoworkPlansItem>
                {/*<StyledCoworkPlansItem>*/}
                {/*  <StyledCoworkPlansHeader>*/}
                {/*    <span>*/}
                {/*      6 MIESIĘCY*/}
                {/*    </span>*/}
                {/*    <StyledCoworkPlanPrice>*/}
                {/*      <strong>1999</strong> <i>zł</i>*/}
                {/*    </StyledCoworkPlanPrice>*/}
                {/*    <StyledPlanType>*/}
                {/*      <div*/}
                {/*        className="selected-option only">hotdesk</div>*/}
                {/*    </StyledPlanType>*/}
                {/*  </StyledCoworkPlansHeader>*/}
                {/*  <StyledCoworkPlansDescription>*/}
                {/*    <ul>*/}
                {/*      <li>miejsce hotdesk</li>*/}
                {/*      <li>dostęp do sprzętu biurowego</li>*/}
                {/*      <li>darmowa kawa/herbata/woda</li>*/}
                {/*      <li>dostęp do szybkiego WiFi</li>*/}
                {/*    </ul>*/}
                {/*  </StyledCoworkPlansDescription>*/}
                {/*</StyledCoworkPlansItem>*/}
              </StyledCoworkPlans>
              }


            { this.state.type == "abonament" &&
              <StyledCoworkPlans>
                <StyledCoworkPlansItem>
                  <StyledCoworkPlansHeader>
                    <span>1 {this.props.t("offerCoworking.month")}</span>
                    <StyledCoworkPlanPrice>
                      <strong>778</strong>
                      <i>zł</i>/{this.props.t("offerCoworking.perMonth")}
                    </StyledCoworkPlanPrice>
                    <StyledPlanType noOptions>
                      <div className="selected-option only">{this.props.t("offerCoworking.monthlyPayment")}</div>
                    </StyledPlanType>
                  </StyledCoworkPlansHeader>
                  <StyledCoworkPlansDescription>
                    <ul>
                      <li>{this.props.t("offerCoworking.perks1")}</li>
											<li>{this.props.t("offerCoworking.perks2")}</li>
											<li>{this.props.t("offerCoworking.perks3")}</li>
											<li>{this.props.t("offerCoworking.perks4")}</li>
											<li>{this.props.t("offerCoworking.perks5")}</li>
                    </ul>
                  </StyledCoworkPlansDescription>
                </StyledCoworkPlansItem>
                <StyledCoworkPlansItem>
                  <StyledCoworkPlansHeader>
                    <span>3 {this.props.t("offerCoworking.months1")}</span>
                    { this.state.plan == "płatność miesięczna" &&
                    <StyledCoworkPlanPrice>
                      <strong>749</strong> <i>zł</i>/{this.props.t("offerCoworking.perMonth")}
                    </StyledCoworkPlanPrice>
                    }
                    {/*{ this.state.plan !== "płatność miesięczna" &&*/}
                    {/*<StyledCoworkPlanPrice>*/}
                    {/*  <strong>3999</strong> <i>zł</i>*/}
                    {/*</StyledCoworkPlanPrice>*/}
                    {/*}*/}
                    <StyledPlanType>
                      <StyledPlanType noOptions>
                        <div className="selected-option only">{this.props.t("offerCoworking.monthlyPayment")}</div>
                      </StyledPlanType>
                      {/*<div*/}
                      {/*  className="selected-option">{this.state.plan}</div>*/}
                      {/*<div className="select-options">*/}
                      {/*  <span onClick={() => this.setState({plan: "płatność miesięczna"})}>Płatność Miesięczna</span>*/}
                      {/*  /!*<span onClick={() => this.setState({plan: "płatność jednorazowa"})}>Płatność Jednorazowa</span>*!/*/}
                      {/*</div>*/}
                    </StyledPlanType>
                  </StyledCoworkPlansHeader>
                  <StyledCoworkPlansDescription>
                    <ul>
                      <li><strong>{this.props.t("offerCoworking.perks6")} (1h)</strong></li>
											<li>{this.props.t("offerCoworking.perks1")}</li>
											<li>{this.props.t("offerCoworking.perks2")}</li>
											<li>{this.props.t("offerCoworking.perks3")}</li>
											<li>{this.props.t("offerCoworking.perks4")}</li>
											<li>{this.props.t("offerCoworking.perks5")}</li>
                    </ul>
                  </StyledCoworkPlansDescription>
                </StyledCoworkPlansItem>
                <StyledCoworkPlansItem>
                  <StyledCoworkPlansHeader>
                    <span>6 {this.props.t("offerCoworking.months2")}</span>
                    { this.state.plan == "płatność miesięczna" &&
                    <StyledCoworkPlanPrice>
                      <strong>730</strong> <i>zł</i>/{this.props.t("offerCoworking.perMonth")}
                    </StyledCoworkPlanPrice>
                    }
                    {/*{ this.state.plan !== "płatność miesięczna" &&*/}
                    {/*<StyledCoworkPlanPrice>*/}
                    {/*  <strong>3999</strong> <i>zł</i>*/}
                    {/*</StyledCoworkPlanPrice>*/}
                    {/*}*/}
                    <StyledPlanType>
                      {/*<div*/}
                      {/*  className="selected-option">{this.state.plan}</div>*/}
                      {/*<div className="select-options">*/}
                      {/*  <span onClick={() => this.setState({plan: "płatność miesięczna"})}>Płatność Miesięczna</span>*/}
                      {/*  /!*<span onClick={() => this.setState({plan: "płatność jednorazowa"})}>Płatność Jednorazowa</span>*!/*/}
                      {/*</div>*/}
                      <StyledPlanType noOptions>
                        <div className="selected-option only">{this.props.t("offerCoworking.monthlyPayment")}</div>
                      </StyledPlanType>
                    </StyledPlanType>
                  </StyledCoworkPlansHeader>
                  <StyledCoworkPlansDescription>
                    <ul>
                      <li><strong>{this.props.t("offerCoworking.perks6")} (2h)</strong></li>
                      <li>{this.props.t("offerCoworking.perks1")}</li>
											<li>{this.props.t("offerCoworking.perks2")}</li>
											<li>{this.props.t("offerCoworking.perks3")}</li>
											<li>{this.props.t("offerCoworking.perks4")}</li>
											<li>{this.props.t("offerCoworking.perks5")}</li>
                    </ul>
                  </StyledCoworkPlansDescription>
                </StyledCoworkPlansItem>

                <StyledCoworkPlansItem active>
                  <StyledCoworkPlansHeader>
                    <span>
                      12 {this.props.t("offerCoworking.months2")}
                    <strong>-14%</strong>
                    </span>
                    { this.state.plan == "płatność miesięczna" &&
                    <StyledCoworkPlanPrice>
                      <strong>699</strong> <i>zł</i>/{this.props.t("offerCoworking.perMonth")}
                    </StyledCoworkPlanPrice>
                    }
                    {/*{ this.state.plan !== "płatność miesięczna" &&*/}
                    {/*<StyledCoworkPlanPrice>*/}
                    {/*  <strong>7999</strong> <i>zł</i>*/}
                    {/*</StyledCoworkPlanPrice>*/}
                    {/*}*/}
                    <StyledPlanType>
                      {/*<div*/}
                      {/*  className="selected-option">{this.state.plan}</div>*/}
                      {/*<div className="select-options">*/}
                      {/*  <span onClick={() => this.setState({plan: "płatność miesięczna"})}>Płatność Miesięczna</span>*/}
                      {/*  /!*<span onClick={() => this.setState({plan: "płatność jednorazowa"})}>Płatność Jednorazowa</span>*!/*/}
                      {/*</div>*/}
                      <StyledPlanType noOptions>
                        <div className="selected-option only">{this.props.t("offerCoworking.monthlyPayment")}</div>
                      </StyledPlanType>
                    </StyledPlanType>
                  </StyledCoworkPlansHeader>
                  <StyledCoworkPlansDescription>
                    <ul>
                      <li><strong>{this.props.t("offerCoworking.perks6")} (3h)</strong></li>
                      <li>{this.props.t("offerCoworking.perks1")}</li>
											<li>{this.props.t("offerCoworking.perks2")}</li>
											<li>{this.props.t("offerCoworking.perks3")}</li>
											<li>{this.props.t("offerCoworking.perks4")}</li>
											<li>{this.props.t("offerCoworking.perks5")}</li>
                      <li>{this.props.t("offerCoworking.perks7")}</li>
                    </ul>
                  </StyledCoworkPlansDescription>
                </StyledCoworkPlansItem>
              </StyledCoworkPlans>
}
              <StyledHourInfo>
                <p classNamne="brutto">{this.props.t("offerCoworking.netto")}</p>
                <Button to="/rezerwacja">{this.props.t("common.reservation")}</Button>
              </StyledHourInfo>
              {/*<StyledCoworkPerks>*/}
              {/*  <ul>*/}
              {/*    {perks.map((item) =>*/}
              {/*      <li key={item.id}>*/}
              {/*        <h3>*/}
              {/*          <StyledCoworkIcon icon={item.icon}></StyledCoworkIcon>*/}
              {/*          {item.name}*/}
              {/*        </h3>*/}
              {/*        <p>{item.description}</p>*/}
              {/*      </li>*/}
              {/*    )}*/}
              {/*  </ul>*/}
              {/*</StyledCoworkPerks>*/}
            </section>
            {/*<br />*/}
            {/*<p>Cowork jest dostępny zawsze w godzinach otwarcia biura, jeżeli zależy Tobie na pracy w innym limicie czasowym odezwij się, a my postaramy Ci się pomóc.</p>*/}
          </StyledOfferContainer>
          <Map />


          <GlobalSection bg="#F4F4F4">
            <StyledFAQContainer>
              <h2>{this.props.t("offerCoworking.aboutTitle1")}</h2>
              <p>
							{this.props.t("offerCoworking.aboutText1")}
              </p>

              <h2>
							{this.props.t("offerCoworking.aboutTitle2")}
              </h2>
              <p>

							{this.props.t("offerCoworking.aboutText2-1")}
              </p>
              <p>
							{this.props.t("offerCoworking.aboutText2-2")}
              </p>
              <p>
							{this.props.t("offerCoworking.aboutText2-3")}
              </p>

              <h2>{this.props.t("offerCoworking.aboutTitle3")}</h2>

              <p>
              {this.props.t("offerCoworking.aboutText3-1")}
              </p>
              <p>
							{this.props.t("offerCoworking.aboutText3-2")}
              </p>
              <p>
							{this.props.t("offerCoworking.aboutText3-3")}
              </p>

              <h2>{this.props.t("offerCoworking.aboutTitle4")}</h2>
              <p>
							{this.props.t("offerCoworking.aboutText4")}
              </p>

              <p>
                <ul>
                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText4-1Title")}
                      </h3>
                      <p>
											{this.props.t("offerCoworking.aboutText4-1Text")}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText4-2Title")}
                      </h3>
                      <p>
											{this.props.t("offerCoworking.aboutText4-2Text")}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText4-3Title")}
                      </h3>
                      <p>
                      {this.props.t("offerCoworking.aboutText4-3Text")}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText4-4Title")}
                      </h3>
                      <p>
											{this.props.t("offerCoworking.aboutText4-4Text")}
                      </p>
                    </div>
                  </li>
                </ul>
              </p>


              <h2>{this.props.t("offerCoworking.aboutTitle5")}</h2>

              <p>
              {this.props.t("offerCoworking.aboutText5")}
              </p>

              <p>
                <ul>
                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText5-1Title")}
                      </h3>
                      <p>
											{this.props.t("offerCoworking.aboutText5-1Text")}
                      </p>
                    </div>
                  </li>

                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText5-2Title")}
                      </h3>
                      <p>
											{this.props.t("offerCoworking.aboutText5-2Text")}
                      </p>
                    </div>
                  </li>

                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText5-3Title")}
                      </h3>
                      <p>
											{this.props.t("offerCoworking.aboutText5-3Text")}
                      </p>
                    </div>
                  </li>

                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText5-4Title")}
                      </h3>
                      <p>
											{this.props.t("offerCoworking.aboutText5-4Text")}
                      </p>
                    </div>
                  </li>

                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText5-5Title")}
                      </h3>
                      <p>
											{this.props.t("offerCoworking.aboutText5-5Text")}
                      </p>
                    </div>
                  </li>

                  <li>
                    <div>
                      <h3>
											{this.props.t("offerCoworking.aboutText5-6Title")}
                      </h3>
                      <p>
											{this.props.t("offerCoworking.aboutText5-6Text")}
                      </p>
                    </div>
                  </li>
                </ul>
              </p>

              <p>
							{this.props.t("offerCoworking.aboutEnding")}
              </p>

            </StyledFAQContainer>
          </GlobalSection>


          <SectionContact />
        </StyledBody>
      ) }
  }
export default withTranslation()(CoworkingPage)
